import {Route, Redirect, Switch} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';

import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';
import ThemeOptions from '../../Layout/ThemeOptions/';

import Frontpage from './Frontpage/';
import Config from './Config/';

import BitfocusWaitForBackend from '../../assets/bitfocus/BitfocusWaitForBackend';
import Instagram from './Instagram';

const Applications = ({match}) => (
	<Fragment>
		<BitfocusWaitForBackend>
			<ThemeOptions/>
			<AppHeader/>
			<div className="app-main">
				<AppSidebar/>
				<div className="app-main__outer">
					<div className="app-main__inner p-0">
						<Switch>
							<Route exact path={`${match.url}/`} component={Frontpage} />
							<Route exact path={`${match.url}/config`} component={Config} />
							<Route exact path={`${match.url}/instagram`} component={Instagram} />
						</Switch>
					</div>
				</div>
			</div>
		</BitfocusWaitForBackend>
	</Fragment>
);

export default Applications;
