import React, { Fragment, Provide5r, useState } from "react";

const UserContext = React.createContext({
  loggedIn: false
});

const UserContextProvider = props => {
  const [loggedIn, setLoggedIn] = useState(!!window.sessionStorage.getItem("token"));

	return (
    <UserContext.Provider
      value={{
        loggedIn,
        setLoggedIn
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
