import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown
} from "reactstrap";

import React, { Fragment } from "react";
import Ionicon from "react-ionicons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast, Bounce } from "react-toastify";
import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/1.jpg";
import { UserContext } from "utils/userContext";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success"
      }
    ));

  render() {
    return (
      <Fragment>
        <UserContext.Consumer>
          {({ loggedInUser }) => (
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    {/*}
										<UncontrolledButtonDropdown>
											<DropdownToggle color="link" className="p-0">
												<FontAwesomeIcon width={42} className="ml-2 opacity-8" icon={faUser}/>
												<FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
											</DropdownToggle>
											<DropdownMenu right className="rm-pointers dropdown-menu-lg">
												<div className="dropdown-menu-header">
													<div className="dropdown-menu-header-inner bg-primary">
														<div className="menu-header-image opacity-2" style={{ backgroundImage: 'url(' + city3 + ')' }} />
														<div className="menu-header-content text-left">
															<div className="widget-content p-0">
																	<div className="widget-content-wrapper">
																		<div className="widget-content-left mr-3">
																			<FontAwesomeIcon width={42} className="ml-2 opacity-8" icon={faUser} />
																		</div>
																		<div className="widget-content-left">
																			<div className="widget-heading">
																				{loggedInUser.displayName}
																			</div>
																			<div className="widget-subheading opacity-8">
																				{loggedInUser.displayDescription}
																			</div>
																		</div>
																		<div className="widget-content-right mr-2">
																			<Button className="btn-pill btn-shadow btn-shine" color="danger">
																				Logout
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="scroll-area-xs" style={{
														height: '250px'
													}}>
														<PerfectScrollbar>
															<Nav vertical>
																<NavItem className="nav-item-header">
																	Activity
																</NavItem>
																<NavItem>
																	<NavLink href="javascript:void(0);">
																			Chat
																			<div className="ml-auto badge badge-pill badge-info">8</div>
																	</NavLink>
																</NavItem>
																<NavItem>
																	<NavLink href="javascript:void(0);">Recover Password</NavLink>
																</NavItem>
																<NavItem className="nav-item-header">
																	My Account
																</NavItem>
																<NavItem>
																	<NavLink href="javascript:void(0);">
																			Settings
																			<div className="ml-auto badge badge-success">New</div>
																	</NavLink>
																</NavItem>
																<NavItem>
																	<NavLink href="javascript:void(0);">
																			Messages
																			<div className="ml-auto badge badge-warning">512</div>
																	</NavLink>
																</NavItem>
																<NavItem>
																	<NavLink href="javascript:void(0);">
																			Logs
																	</NavLink>
																</NavItem>
															</Nav>
														</PerfectScrollbar>
													</div>
													<Nav vertical>
														<NavItem className="nav-item-divider mb-0"/>
													</Nav>
													<div className="grid-menu grid-menu-2col">
														<Row className="no-gutters">
															<Col sm="6">
																<Button
																		className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
																		outline color="primary">
																		<i className="pe-7s-chat icon-gradient bg-premium-dark btn-icon-wrapper mb-2"> </i>
																		Message Inbox
																</Button>
															</Col>
															<Col sm="6">
																<Button
																		className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
																		outline color="danger">
																		<i className="pe-7s-ticket icon-gradient bg-premium-dark btn-icon-wrapper mb-2"> </i>
																		<b>User Support</b>
																</Button>
															</Col>
														</Row>
													</div>
													<Nav vertical>
															<NavItem className="nav-item-divider"/>
															<NavItem className="nav-item-btn text-center">
																	<Button size="sm" className="btn-wide" color="primary">
																			Open Messages
																	</Button>
															</NavItem>
													</Nav>
											</DropdownMenu>
										</UncontrolledButtonDropdown>
										*/}
                  </div>
                  {/*
									<div className="widget-content-left  ml-3 header-user-info">
										<div className="widget-heading">
											{loggedInUser.displayName}
										</div>
										<div className="widget-subheading">
											{loggedInUser.displayDescription}
										</div>
									</div>
									*/}
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Fragment>
    );
  }
}

export default UserBox;
