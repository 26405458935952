import React from "react";
import { generate } from "shortid";
import WindowSizeListener from "react-window-size-listener";
import style from "./style.module.css";
import BgImage from "./bg.jpg";
import { Polaroid } from "./Polaroid";
import BackgroundImage from "../BackgroundImage";

class ReverseRain extends React.Component {
  constructor() {
    super();

    this.state = {
      active: []
    };

    this.colors = ["#222"];
    this.textcolor = "#ffffff";
    this.travelFrom = 0;
    this.colorcounter = 0;
    this.travelWidth = 0;
    this.overshoot = 1000;
    this.secondsBetween = 1000;
    this.zSpreadPos = 0;
    this.currentIndex = 0;
    this.lastAdded = 0;
    this.pixelsPerSecond = 70;
    this.active = [];
    this.frame = this.frame.bind(this);
    this.lastTick = 0;
    this.running = false;
    this.imageWidth = 500;
  }

  componentDidUpdate() {
    if (this.props.pps !== undefined) {
      this.pixelsPerSecond = this.props.pps;
    }
    if (this.props.between !== undefined) {
      this.secondsBetween = this.props.between;
    }
    if (this.props.imagesize !== undefined) {
      this.imageWidth = this.props.imagesize;
    }
    if (this.props.fontsize !== undefined) {
      this.fontSize = this.props.fontsize;
    }
    if (this.props.font !== undefined) {
      this.font = this.props.font;
    }
    if (this.props.colors !== undefined) {
      this.colors = this.props.colors;
    }
    if (this.props.textcolor !== undefined) {
      this.textcolor = this.props.textcolor;
    }

    let removed = 0;
    let newActive = [];

    this.state.active.forEach(active => {
      let find = this.props.imageset.find(item => item.ts === active.ts);
      if (find !== undefined) {
        newActive.push({ ...active });
      } else {
        removed++;
      }
    });

    if (removed) {
      this.setState({ active: [...newActive] });
    }
  }
  componentDidMount() {
    this.startTime = Date.now();
    this.active = [];
    this.running = true;
    window.requestAnimationFrame(this.frame);
  }

  componentWillUnmount() {
    this.running = false;
  }

  frame(tick) {

    this.active = this.state.active;

    // Don't draw if we haven't gotten our width yet
    if (this.travelWidth === 0) {
      if (this.running) window.requestAnimationFrame(this.frame);
      return;
    }

    // Delta from last frame
    const delta = (tick - this.lastTick) / 1000;
    this.lastTick = tick;

    this.active = this.active.map(item => {
      return { ...item, y: item.y - this.pixelsPerSecond * delta * item.z };
    });
    this.active = this.active.filter(
      item => item.y > 0 - (item.ref ? item.ref.clientHeight : this.overshoot)
    );

    // If it's time to add a new image to the canvas
    let now = Date.now();
    if (now - this.secondsBetween > this.lastAdded) {
      this.lastAdded = now;
      this.currentIndex = (this.currentIndex + 1) % this.props.imageset.length;

      if (
        this.props.imageset[this.currentIndex] !== undefined &&
        this.props.imageset[this.currentIndex].ts !== undefined
      ) {
        this.active.push({
          ...this.props.imageset[this.currentIndex],
          id: generate(),
          y: this.travelFrom,
          z: Math.random() * 0.5 + 0.5,
          bg: this.colors[this.colorcounter++ % this.colors.length],
          textcolor: this.textcolor,
          x: Math.floor((this.travelWidth / 100) * (this.zSpreadPos % 100))
        });
      }

      this.zSpreadPos += Math.floor(Math.random() * 40);
    }

    // Update state with current elements
    this.setState({ active: [...this.active] });

    if (this.running) window.requestAnimationFrame(this.frame);
  }

  render() {
    this.travelWidth = this.width - this.imageWidth;
    this.travelFrom = this.height + 0;

    return (
      <React.Fragment>
        <WindowSizeListener
          onResize={windowSize => {
            this.height = windowSize.windowHeight;
            this.width = windowSize.windowWidth;
          }}
        />
        <BackgroundImage url={this.props.backgroundUrl || BgImage} />
        {this.state.active.map(item => (
          <Polaroid
            key={item.id}
            imageWidth={this.imageWidth}
            font={this.font}
            fontSize={this.fontSize}
            item={item}
          ></Polaroid>
        ))}
      </React.Fragment>
    );
  }
}

export default ReverseRain;
