import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import React, { Fragment } from "react";
import Login from "../../Pages/Login";
import Screen from "../../Pages/Screen/index.tsx";
import Upload from "../../Pages/Upload";
import Applications from "../../Pages/Applications";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../utils/userContext";
import { useContext } from "react";
import { useEffect } from "react";

const AppMain = () => {
  const { loggedIn, setLoggedIn } = useContext(UserContext);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        loggedIn === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  return (
    <Fragment>
      <Route exact path="/" component={Upload} />
      <Route exact path="/screen" component={Screen} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute path="/app" component={Applications} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
