import { useState, useEffect } from "react";

const cache = [];

function cacheImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = err => reject(err);
  });
}

export const useImageCache = (images: any[]) => {
  const [cachedImages, setCachedImages] = useState<
    { id: string; image: string }[]
  >([]);

  useEffect(() => {
    const cacheImages = async () => {
      const cachedImages = await Promise.all(
        images.map(async image => {
          if (cache.find(c => c.id === image.id)) return image;
          try {
            void (await cacheImage(image.image));
          } catch (e) {
            console.error(e);
            return null;
          }
          cache.push(image);
          return image;
        })
      );
      setCachedImages(cachedImages.filter(c => c !== null));
    };
    cacheImages();
  }, [images]);

  return cachedImages;
};
