import React from "react";
import WindowSizeListener from "react-window-size-listener";
import style from "./style.module.css";
//import BgImage from "./bg.jpg";
//import BackgroundImage from "../BackgroundImage";
import { useEffect } from "react";
import { FlippingImage } from "./FlippingImage.tsx";

let unseen = [];

export const Sociawall = ({
  backgroundUrl,
  imageset,
  between,
  enableText,
  font,
  imagesize,
  fontsize,
  colors,
  textcolor
}: {
  backgroundUrl: string;
  imageset: Array<{ id: string; image: string }>;
  between: number;
  enableText: boolean;
  imagesize: number;
  font: string;
  colors: any;
  textcolor: string;
  fontsize: number;
}) => {
  const [screenSize, setScreenSize] = React.useState({ width: 0, height: 0 });
  const [tileCount, setTileCount] = React.useState({ x: 0, y: 0, total: 0 });
  const [tiles, setTiles] = React.useState([]);
  const [lastTiles, setLastTiles] = React.useState([]);
  const [lastImages, setLastImages] = React.useState([]);
  const [previousImageset, setPreviousImageset] = React.useState([]);

  const getImage = () => {
    if (imageset.length === 0) return;

    if (unseen.length > 0) {
      return unseen.shift();
    }

    // we don't have enough images
    if (imageset.length < tileCount.total) {
      const newImage = imageset[Math.floor(Math.random() * imageset.length)];
      setLastImages([...lastImages, newImage]);
      return newImage;
    }
    // we have enough to not repeat images
    const newImage = imageset[Math.floor(Math.random() * imageset.length)];
    if (tiles.find(i => newImage && i && newImage.id === i.id))
      return getImage();
    let newLastImages = [...lastImages, newImage];
    if (newLastImages.length > imageset.length / 2) newLastImages.shift();
    setLastImages(newLastImages);
    return newImage;
  };

  const getTile = () => {
    if (tileCount.total === 0) return 0;
    // return a random tileNumber that is not in the half of the last tiles
    const newTile = Math.floor(Math.random() * tileCount.total);
    if (lastTiles.includes(newTile)) return getTile();
    setLastTiles(
      [...lastTiles, newTile].splice(-Math.floor(tileCount.total / 2))
    );
    return newTile;
  };

  useEffect(() => {
    // clean up tiles that are not in the imageset any more!
    const newTiles = tiles.map(tile =>
      imageset.find(i => i.id === tile.id) ? tile : getImage()
    );
    if (newTiles.length !== tiles.length) {
      console.log(
        "newTiles.length !== tiles.length",
        newTiles.length,
        tiles.length
      );
    }
    setTiles(newTiles);

    // find new images that wasn't in the old imageset
    const newImages = imageset.filter(
      i => !previousImageset.find(p => p.id === i.id)
    );

    if (newImages.length > 0 && newImages.length !== imageset.length) {
      unseen = [...unseen, ...newImages];
    }

    setPreviousImageset([...imageset]);
  }, [imageset]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTiles = [...tiles];
      newTiles[getTile()] = getImage();
      setTiles(newTiles);
    }, between);
    return () => clearInterval(interval);
  }, [tiles, between]);

  // ON RESIZE & LOAD
  useEffect(() => {
    unseen = [];
    setLastImages([]);
    setLastTiles([]);
    const newTiles = [];
    for (let i = 0; i < tileCount.total; i++) {
      const newImg = getImage();
      if (i < imageset.length / 2) {
        setLastImages([...lastImages, newImg]);
      }
      newTiles.push(newImg);
    }
    setTiles(newTiles);
  }, [tileCount]);

  useEffect(() => {
    console.log("context change!!");
  }, [fontsize, font, colors, textcolor]);

  useEffect(() => {
    const x = Math.floor(screenSize.width / imagesize);
    const y = Math.floor(screenSize.height / imagesize);
    setTileCount({ x, y, total: x * y });
  }, [screenSize, imagesize]);

  const cssBaseStyle = {
    position: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    borderRadius: "5px"
  };

  return (
    <React.Fragment>
      <WindowSizeListener
        onResize={windowSize => {
          setScreenSize({
            width: windowSize.windowWidth,
            height: windowSize.windowHeight
          });
        }}
      />

      {/*<BackgroundImage url={backgroundUrl || BgImage} />*/}

      <div
        className={style.animation}
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "black"
        }}
      >
        <div style={{ overflow: "hidden" }}>
          {tiles.map((tile, i) => {

						if (!tile) return null;

            const x = i % tileCount.x;
            const y = Math.floor(i / tileCount.x);

            const cssPosition = {
              top: y * (screenSize.height / tileCount.y) + 2,
              left: x * (screenSize.width / tileCount.x) + 2,
              height: screenSize.height / tileCount.y - 4,
              width: screenSize.width / tileCount.x - 4
            };

            if (!enableText) {
              return (
                <FlippingImage
                  key={i}
                  style={{ ...cssBaseStyle, ...cssPosition }}
                  image={tile.image}
                />
              );
            }

            return (
              <FlippingImage
                key={i}
                style={{ ...cssBaseStyle, ...cssPosition }}
                image={tile.image}
                textStyle={{
                  backgroundColor: colors[0] || "rgba(0,0,0,0.3)",
                  color: textcolor,
                  margin: "0 auto",
                  fontSize: fontsize / 3,
                  lineHeight: "1.1em",
                  fontFamily: font,
                  padding: "0.3em 0.5em",
                  borderTopRightRadius: "0.3em",
                  position: "absolute",
                  zIndex: 1,
                  bottom: 0
                }}
                text={tile.label}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sociawall;
