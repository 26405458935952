import React, { Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Progress,
  Container
} from "reactstrap";

// Layout

export default class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      file: null,
      progress: 0,
      fileInputKey: "init"
    };

    this.value = "";

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  sendFile(text, file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.addEventListener("load", event => {
        console.log("req status; ", req.status, req.statusText);
        if (req.status === 200) {
          console.log("Result:", req.response);
          if (typeof req.response === "string") {
            resolve(JSON.parse(req.response));
          } else {
            resolve(req.response);
          }
        } else {
          reject(event);
        }
      });

      req.addEventListener("error", event => {
        reject(event);
      });

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          this.setState({
            progress: (event.loaded / event.total) * 100
          });
        } else {
          // We don't know progress
          this.setState({
            progress: -1
          });
        }
      });

      req.open("POST", "/upload/simple");
      req.send(formData);
    });
  }

  async submitForm(event) {
    console.log("OnSubmit da");
    if (event !== undefined) {
      event.preventDefault();
    }

    this.setState({
      uploading: true
    });

    try {
      if (this.state.file === null) {
        console.log("Null");
        return;
      }
      let result = await this.sendFile(this.state.text, this.state.file);

      if (this.props.setUrl) {
        this.props.setUrl(result.filename);
      }

      this.setState({
        file: null,
        value: result.filename,
        text: "",
        errorUploading: false,
        successfullUpload: true,
        uploading: false,
        progress: 0,
        fileInputKey: Date.now() // reset file field
      });
    } catch (e) {
      console.error("sendfile", e);
      this.setState({
        errorUploading: true,
        successfullUpload: false,
        uploading: false,
        progress: 0
      });
    }
  }

  onChangeHandler(event) {
    console.log("CHanged: ", event.target.id);

    if (event.target.type === "file") {
      this.setState(
        {
          file: event.target.files[0]
        },
        () => this.submitForm()
      );
    }
  }

  render() {
    return (
      <Fragment>
        <Form onSubmit={this.submitForm}>
          <fieldset
            style={{ padding: 0, margin: 0 }}
            disabled={this.state.uploading}
          >
            <input
              type="file"
              className="form-control"
              onChange={this.onChangeHandler}
              style={{
                width: "100%",
                fontSize: 14,
                borderRadius: 4
              }}
            />
            {this.state.uploading && (
              <Progress value={this.state.progress} color="success">
                {Math.round(this.state.progress)}%
              </Progress>
            )}
            {this.state.errorUploading && (
              <div className="text-danger">
                Problemer med opplastningen, prøv igjen.
              </div>
            )}
            {this.state.successfullUpload && (
              <div className="text-success">Filen lastet opp</div>
            )}
          </fieldset>
        </Form>
      </Fragment>
    );
  }
}
