import React, {Fragment, Component} from "react";
import { backendContext } from 'utils/backendStoreSocketClient';

export class AppLanguage extends Component {

  static contextType = backendContext;

  render() {

    const backend = this.context || {}
    const backendState = backend.state || {}
    const db = backendState.db || {}
    const i18n = db.i18n || []
    const find = i18n.find(obj => obj.id === this.props.id);
    if (find === undefined) {
      return (
        <Fragment>
          ({this.props.id}?)
        </Fragment>
      )
    }
    return (
      <Fragment>
        {find.value}
      </Fragment>
    );
  }

}
