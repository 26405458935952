import React from "react";
import { generate } from "shortid";
import WindowSizeListener from "react-window-size-listener";
import style from "./style.module.css";
import BgImage from "./bg.jpg";
import { Polaroid } from "./Polaroid";
import BackgroundImage from "../BackgroundImage";

class SingleSwoop extends React.Component {
  constructor() {
    super();

    this.state = {
      active: []
    };
    this.updateInterval = this.updateInterval.bind(this);
    this.changePicture = this.changePicture.bind(this);
    this.imageIndex = 0;
  }

  componentDidUpdate() {
    if (this.props.between !== undefined) {
      if (this.secondsBetween !== this.props.between) {
        this.updateInterval();
      }
      this.secondsBetween = this.props.between;
    }

    if (this.props.imagesize !== undefined) {
      this.imageWidth = this.props.imagesize;
    }

    if (this.props.fontsize !== undefined) {
      this.fontSize = this.props.fontsize;
    }

    if (this.props.font !== undefined) {
      this.font = this.props.font;
    }

    if (this.props.colors !== undefined) {
      this.colors = this.props.colors;
    }

    if (this.props.textcolor !== undefined) {
      this.textcolor = this.props.textcolor;
    }

    if (this.props.imageset !== undefined) {
      this.imageset = this.props.imageset;
    }
  }

  changePicture() {
    let newState = [];
    if (this.state.active.length === 0) {
      let count = 0;
      [this.newPic(), this.newPic(), this.newPic()].forEach(item => {
        count++;
        newState.push({
          ...item,
          status: count,
          id: Math.floor(Math.random() * 10000000)
        });
      });
      this.setState({ active: newState });
    } else {
      this.state.active.forEach(obj => {
        if (obj.status < 3) {
          newState.push({
            ...obj,
            status: obj.status + 1
          });
        }
      });

      let item = this.newPic();

      newState.push({
        ...item,
        status: 1
      });

      this.setState({ active: newState });
    }
  }

  newPic() {
    if (this.props.imageset !== undefined) {
      this.imageIndex++;

      if (this.imageIndex === this.props.imageset.length) {
        this.imageIndex = 0;
      }

      if (this.props.imageset[this.imageIndex] !== undefined) {
        let obj = this.props.imageset[this.imageIndex];
        return {
          ...obj,
          id: Math.floor(Math.random() * 10000000000)
        };
      }
    }
  }

  updateInterval() {
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(this.changePicture, this.props.between);
  }

  componentDidMount() {
    this.running = true;
    this.changePicture();
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    document.body.style.width = "100vw";
  }

  componentWillUnmount() {
    this.running = false;
    if (this.timer) clearInterval(this.timer);
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.body.style.width = "auto";
  }

  render() {
    let classes = [
      "",
      style.effectIncoming,
      style.effectActual,
      style.effectOutgoing
    ];
    return (
      <React.Fragment>
        <WindowSizeListener
          onResize={windowSize => {
            this.height = windowSize.windowHeight;
            this.width = windowSize.windowWidth;
          }}
        />
        <BackgroundImage url={this.props.backgroundUrl || BgImage} />
        <div
          className={style.animation}
          style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
        >
          {this.state.active.map(item => (
            <img
              className={classes[item.status]}
              key={item.id}
              src={item.image}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default SingleSwoop;
