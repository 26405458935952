import React from 'react';
import { Table, Button } from 'reactstrap';

export default class BitfocusTable extends React.Component {
	constructor(props, context) {
		super(props);
		this.state = {};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	}

	render() {

		var dataset = this.props.dataset || [];
		var config = this.props.config || [];
		var empty = this.props.empty || null;
		var actions = this.props.actions || {};

		//var ds = dataset.sort((a,b) => a.id - b.id);

		// enhance config
		config = config.map((headitem)=>{
			return {
				...headitem,
				field: headitem.field || headitem.id
			}
		})

		var labels = 0;
		for (var x in config) {
			if (config[x].label !== undefined && config[x].label !== null) { 
				labels++;
			}
		}

		if (dataset.length === 0 && empty !== null) {
			return (<em>{empty}</em>)
		}

		return (
			<Table hover className="mb-0">{labels ? (
				<thead>
					<tr>
						{config.map((item, i) => <th key={item.id}>{item.label}</th>) }
					</tr>
				</thead>
				) : undefined }<tbody>
					{dataset.map((item, i) => (
						<tr key={item.id || i} onClick={()=>{ 
								if (item.href !== undefined ) window.location.href = item.href 
								if (item.onClick !== undefined ) item.onClick(item.id)
							}}>
							{config.map((headitem, i) => {
								if (headitem.id === '_actions') {
									return (
										<td key={headitem.id} className="text-right">
											{ actions.map((action,i) => (
												<Button {...action} onClick={(e)=>action.onClick(item.id, item, e)} key={i} style={{marginRight:2}}>{action.label}</Button>
											))}
										</td>
									)
								} 
								else {
									return (
										<td key={headitem.id}>
											{headitem.customRender !== undefined ?
												headitem.customRender(item[headitem.field], item, headitem) :
												item[headitem.field]}
										</td>
									);
								}
							})}
						</tr>
					))}
				</tbody>
			</Table>
		);
	}
}
