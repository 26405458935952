import React, { useState, useEffect } from "react";

export const FlippingImage = ({
  style,
  image,
  text,
  textStyle = {}
}: {
  style: React.CSSProperties;
  image: string;
  textStyle?: React.CSSProperties;
  text?: string;
  extras: React.DOMElement;
}) => {
  const [inAnimation, setInAnimation] = useState(false);
  const [currentImage, setCurrentImage] = useState(image);
  const [nextImage, setNextImage] = useState(image);
  const [currentText, setCurrentText] = useState(text);

  useEffect(() => {
    if (inAnimation) return;
    if (image === currentImage) return;
    if (image === nextImage) return;
    setInAnimation(true);
    setNextImage(image);

    setTimeout(() => {
      setCurrentImage(image);
      setCurrentText(text);
      setInAnimation(false);
    }, 1000);
  }, [image, currentImage, nextImage]);

  return (
    <div>
      <div
        style={{
          ...style,
          backgroundImage: "url(" + currentImage + ")"
        }}
      >
        {currentText !== undefined && (
          <div
            style={{
              ...textStyle
            }}
          >
            {currentText}
          </div>
        )}
      </div>

      <div
        style={{
          ...style,
          backgroundImage: "url(" + nextImage + ")",
          opacity: inAnimation ? 1 : 0,
          transition: "opacity 0.7s ease-in-out"
        }}
      >
        <div
          style={{
            ...textStyle
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default FlippingImage;
