import React, { Fragment, Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { backendContext } from "utils/backendStoreSocketClient";
import cx from "classnames";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import BitfocusTable from "assets/bitfocus/BitfocusTable";
import Upload from "./Upload";
import {
  Col,
  Button,
  FormGroup,
  Card,
  Nav,
  NavItem,
  NavLink,
  Container,
  CardBody,
  CardTitle,
  Row,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Form
} from "reactstrap";
import InputRange from "react-input-range";
import { ColorManager } from "./ColorManager";

export default class Config extends Component {
  static contextType = backendContext;

  constructor() {
    super();

    this.state = {
      active: false
    };

    this.setScreenEffect = this.setScreenEffect.bind(this);
  }

  plural(count, word) {
    if (count === 1) return count + " " + word;
    return count + " " + word + "s";
  }

  setScreenEffect(form) {
    const backend = this.context || {};
    const backendState = backend.state || {};
    const db = backendState.db || {};
    backend.setState({ db: { ...db, screenEffect: form.target.value } });
  }

  render() {
    const backend = this.context || {};
    const backendState = backend.state || {};
    const db = backendState.db || {};
    const i18n = db.i18n || [];

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div>
            <div
              className={cx("app-inner-layout chat-layout", {
                "open-mobile-menu": this.state.active
              })}
            >
              <div className="app-inner-layout__header text-white bg-dark">
                <PageTitle
                  heading="Innstillinger"
                  subheading="Lagres automatisk"
                  icon="pe-7s-umbrella icon-gradient bg-sunny-morning"
                />
              </div>
            </div>
          </div>

          <Container className="mt-3" fluid>
            <Card>
              <CardBody>
                <CardTitle>Effektinnstillinger</CardTitle>
                <div className="mt-3 mb-3">
                  <Label>Visningsmåte på storskjerm</Label>
                  <div>
                    <select
                      value={db.screenEffect || "ReverseRain"}
                      onChange={this.setScreenEffect}
                    >
                      <option key="ReverseRain" value="ReverseRain">
                        Regn med mange
                      </option>
                      <option key="Sociawall" value="Sociawall">
                        Sociawall
                      </option>

                      <option key="SingleSwoop" value="SingleSwoop">
                        Enkeltbilder
                      </option>
                    </select>
                  </div>
                </div>

                <div>
                  <div className="mt-3 mb-3">
                    <Label>Tid mellom bilder (ms)</Label>
                    <InputRange
                      maxValue={15000}
                      minValue={100}
                      value={db.between || 1000}
                      onChange={between =>
                        backend.setState({ db: { ...db, between } })
                      }
                    />
                  </div>
                </div>
                {db.screenEffect !== "SingleSwoop" && (
                  <div>
                    {db.screenEffect !== "Sociawall" && (
                      <div className="mt-5 mb-5">
                        <Label>Pixels per minute</Label>
                        <div>
                          <InputRange
                            maxValue={800}
                            minValue={10}
                            value={db.pps || 179}
                            onChange={pps =>
                              backend.setState({ db: { ...db, pps } })
                            }
                          />
                        </div>
                      </div>
                    )}

                    <div className="mt-5 mb-5">
                      <Label>Image width</Label>
                      <InputRange
                        maxValue={1000}
                        minValue={200}
                        value={db.imagesize || 520}
                        onChange={imagesize =>
                          backend.setState({ db: { ...db, imagesize } })
                        }
                      />
                    </div>

                    <div className="mt-5 mb-5">
                      <Label>Font size</Label>
                      <InputRange
                        maxValue={110}
                        minValue={30}
                        value={db.fontsize || 30}
                        onChange={fontsize =>
                          backend.setState({ db: { ...db, fontsize } })
                        }
                      />
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row className="mt-3">
              <Col md={6}>
                <Card className="">
                  <CardBody>
                    <CardTitle>Farger</CardTitle>
                    <ColorManager />
                  </CardBody>
                </Card>

                <Card className="mt-3">
                  <CardBody>
                    <CardTitle>Tekst</CardTitle>
                    {i18n.map(item => (
                      <FormGroup key={item.id}>
                        <Label>{item.label}</Label>
                        {item.type === "text" && (
                          <Input
                            type="text"
                            value={item.value}
                            onChange={e => {
                              let newi18n = [];
                              i18n.map(i => {
                                let obj = { ...i };
                                if (obj.id === item.id) {
                                  obj.value = e.target.value;
                                }
                                newi18n.push(obj);
                                return obj;
                              });

                              backend.setState({
                                db: { ...db, i18n: [...newi18n] }
                              });
                            }}
                          />
                        )}
                      </FormGroup>
                    ))}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Font</CardTitle>
                    <Input
                      type="text"
                      name="font"
                      value={db.font || "Helvetica"}
                      onChange={e =>
                        backend.setState({
                          db: { ...db, font: e.target.value }
                        })
                      }
                    />
                    <div className="mt-2">
                      <em>
                        Finn en fin font på{" "}
                        <strong>
                          <a href="https://fonts.google.com/" target="_new">
                            Google Fonts
                          </a>
                        </strong>
                      </em>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mt-3">
                  <CardBody>
                    <CardTitle>Funksjonalitet</CardTitle>
                    <Row>
                      <Col md={6}>
                        <FormGroup
                          tag="fieldset"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <legend>Moderering</legend>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={e =>
                                  backend.setState({
                                    db: { ...db, autoAccept: e.target.checked }
                                  })
                                }
                                checked={db.autoAccept || false}
                              />{" "}
                              Godkjenn nye bilder automatisk
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup
                          tag="fieldset"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <legend>Innsending</legend>

                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="systemtype"
                                value="image"
                                onChange={e =>
                                  backend.setState({
                                    db: { ...db, systemType: e.target.value }
                                  })
                                }
                                checked={db.systemType === "image"}
                              />{" "}
                              Kun bilde
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="systemtype"
                                value="text"
                                onChange={e =>
                                  backend.setState({
                                    db: { ...db, systemType: e.target.value }
                                  })
                                }
                                checked={db.systemType === "text"}
                              />{" "}
                              Kun tekst
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="systemtype"
                                value="imagetext"
                                onChange={e =>
                                  backend.setState({
                                    db: { ...db, systemType: e.target.value }
                                  })
                                }
                                checked={db.systemType === "imagetext"}
                              />{" "}
                              Tekst og bilde
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="mt-3">
                  <CardBody>
                    <CardTitle>Bilder</CardTitle>
                    <FormGroup>
                      <legend>Screen - Bakgrunn</legend>
                      <Row>
                        <Col md={6}>
                          <Upload
                            setUrl={backgroundUrl =>
                              backend.setState({ db: { ...db, backgroundUrl } })
                            }
                          />
                        </Col>
                        <Col md={6}>
                          {db.backgroundUrl && (
                            <img
                              src={db.backgroundUrl}
                              style={{ maxWidth: "100%", maxHeight: 200 }}
                            />
                          )}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <legend>Upload - Bakgrunn</legend>
                      <Row>
                        <Col md={6}>
                          <Upload
                            setUrl={uploadBackgroundUrl =>
                              backend.setState({
                                db: { ...db, uploadBackgroundUrl }
                              })
                            }
                          />
                        </Col>
                        <Col md={6}>
                          {db.uploadBackgroundUrl && (
                            <img
                              src={db.uploadBackgroundUrl}
                              style={{ maxWidth: "100%", maxHeight: 200 }}
                            />
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
