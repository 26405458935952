import React, { Fragment, Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { backendContext } from "utils/backendStoreSocketClient";
import cx from "classnames";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import BitfocusTable from "assets/bitfocus/BitfocusTable";

import {
  Col,
  Button,
  FormGroup,
  Card,
  Nav,
  NavItem,
  NavLink,
  Container,
  CardBody,
  CardTitle,
  Row,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Form
} from "reactstrap";
import InputRange from "react-input-range";


export default class Instagram extends Component {
  static contextType = backendContext;

  constructor() {
    super();

		this.timer = null;
    this.state = {
      active: false,
			authorizing: false
    };
 	}

	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	}

	componentDidMount() {
		// make sure we update the page every minute for the counters
		this.timer = setInterval(() => {
			this.setState({});
		}, 60000);

		this.setState({
			authorizing: false
		})
	}

	formatTime(seconds) {
		var days = Math.floor(seconds / 86400);
		var hours = Math.floor((seconds % 86400) / 3600);
		var minutes = Math.floor((seconds % 3600) / 60);
		
		if (days > 0) {
			return days + " dager, " + hours + " timer, " + minutes + " minutter";
		} else if (hours > 0) {
			return hours + " timer, " + minutes + " minutter";
		} else {
			return minutes + " minutter";
		}
	}

  render() {
    const backend = this.context || {};
    const backendState = backend.state || {};
    const db = backendState.db || {};
		const instagram = db.instagram || {};

		const unAuth = () => {
			backend.setState({
				db: {
					...db,
					instagram: {
						...instagram,
						ttl: 0,
						token: null,
						username: null,
						user_id: null,
					}
				}
			});
		}
	
		const getAuthUrl = async () => {
			try {
				this.setState({ authorizing: true });
				const url = await backend.getInstagramAuthUrl();
				window.location.href = url;
			} catch (err) {
				console.error("Error fetching url: ", err)
				this.setState({ authorizing: false });
			}
		};

		const ttl = instagram.ttl ? db.instagram.ttl : 0;
		const timeLeft = ttl - Date.now();
		const lastSync = instagram.lastSync ? Date.now() - instagram.lastSync : 0;

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div>
            <div
              className={cx("app-inner-layout chat-layout", {
                "open-mobile-menu": this.state.active
              })}
            >
              <div className="app-inner-layout__header text-white bg-dark">
                <PageTitle
                  heading="Instagram bilder"
                  subheading="Automatisk innhenting av bilder fra Instagram"
                  icon="pe-7s-umbrella icon-gradient bg-sunny-morning"
                />
              </div>
            </div>
          </div>

					<Container fluid>
						<Row className="mt-3">
							<Col md={6}>
								<Card>
									<CardBody>
										<CardTitle>Autorisasjon</CardTitle>
										<div className="mt-3 mb-3">
											<p>For å laste ned bilder fra din instagramkonto så må vi få tilgang til kontoen din. Vennligst trykk på knappen nedenfor for å begynne autorisasjonen.</p>
											<Button
												color="success"
												disabled={this.state.authorizing}
												onClick={() => getAuthUrl()}
											>
												{this.state.authorizing ? (
													<>
													  Autoriserer... <FontAwesomeIcon icon={faSpinner} spin />
													</>
												) :
													"Autoriser"
												}
											</Button>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card>
									<CardBody>
										<CardTitle>Innstillinger</CardTitle>
										<div className="mt-3 mb-3">
											<FormGroup
														tag="fieldset"
														style={{ padding: 0, margin: 0 }}
													>
												<FormGroup check>
													<Label check>
														<Input
															type="checkbox"
															onChange={e =>
																backend.setState({
																	db: {
																		...db,
																		instagram: {
																			...instagram,
																			autoAccept: e.target.checked
																		}
																	}
																})
															}
															checked={instagram.autoAccept || false}
														/>{" "}
														Godkjenn nye bilder fra instagram automatisk
													</Label>
												</FormGroup>
											</FormGroup>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					{timeLeft > 0 ? (
						<Container className="mt-3" fluid>
							<Card>
								<CardBody>
									<CardTitle>Status</CardTitle>
									<div className="mt-3 mb-3">
										<p>Logget inn på instagram som @{db.instagram.username}</p>
										<p>Din instagram login er gyldig i {this.formatTime(timeLeft/1000)} til.</p>
										{lastSync > 0 ? (
											<p>Sjekket etter nye media fra instagram for {this.formatTime(lastSync/1000)} siden.</p>
										) : null}
									</div>
									<Button color="warning" onClick={() => unAuth()}>Koble fra</Button>
								</CardBody>
							</Card>
						</Container>
					) : null}

        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
