import React, { Fragment, useState, useContext } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Label,
  Input,
  Progress,
  Container
} from "reactstrap";
import BgImage from "./bg.jpg";
import { AppLanguage, getString } from "AppLanguage";
import { FileInput, FormGroup, H5, InputGroup } from "@blueprintjs/core";
import { backendContext, BackendContext } from "utils/backendStoreSocketClient";

// Layout

export default class Upload extends React.Component {
  static contextType = backendContext;

  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      files: [],
      text: "",
      progress: 0,
      totalProgress: 0,
      fileInputKey: "init"
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  sendFile(text, file, baseProgress) {
    return new Promise((resolve, reject) => {
      const backend = this.context || {};
      const backendState = backend.state || {};
      const db = backendState.db || {};
      const req = new XMLHttpRequest();

      const formData = new FormData();
      if (db.systemType === "text" || db.systemType === "imagetext") {
        formData.append("text", text);
      }
      if (db.systemType === "image" || db.systemType === "imagetext") {
        formData.append("file", file, file.name);
      }

      req.addEventListener("load", event => {
        console.log("req status; ", req.status, req.statusText);
        if (req.status === 200) {
          resolve();
        } else {
          reject(event);
        }
      });

      req.addEventListener("error", event => {
        reject(event);
      });

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          this.setState({
            progress: baseProgress + (event.loaded / event.total) * 100
          });
        } else {
          // We don't know progress
          this.setState({
            progress: -1
          });
        }
      });

      req.open("POST", "/upload/image");
      req.send(formData);
    });
  }

  async submitForm(event) {
    event.preventDefault();

    this.setState({
      uploading: true,
      totalProgress: this.state.files.length * 100
    });

    try {
      for (let i = 0; i < this.state.files.length; i++) {
        await this.sendFile(this.state.text, this.state.files[i], i * 100);
      }

      this.setState({
        files: [],
        text: "",
        errorUploading: false,
        successfullUpload: true,
        uploading: false,
        progress: 0,
        fileInputKey: Date.now() // reset file field
      });
    } catch (e) {
      this.setState({
        errorUploading: true,
        successfullUpload: false,
        uploading: false,
        progress: 0
      });
    }
  }

  onChangeHandler(event) {
    if (event.target.id === "file") {
      this.setState({
        files: event.target.files
      });
    } else if (event.target.id === "text") {
      this.setState({
        text: event.target.value
      });
    }
  }

  render() {
    const backend = this.context || {};
    const backendState = backend.state || {};
    const db = backendState.db || {};

    const backgroundImage = db.uploadBackgroundUrl || BgImage;
    console.log("Render form", db);
    return (
      <Fragment>
        <div
          className="h-100"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        >
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <Form onSubmit={this.submitForm}>
                <fieldset disabled={this.state.uploading}>
                  <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="h5 modal-title text-center">
                          <h4 className="mt-2">
                            <div className="mb-2">
                              <AppLanguage id="upload_title" />
                            </div>
                            <span>
                              <AppLanguage id="upload_subtitle" />
                            </span>
                          </h4>
                        </div>
                        <Row form style={{ textAlign: "center" }}>
                          {["image", "imagetext"].indexOf(db.systemType) !==
                            -1 && (
                            <Col md={12}>
                              <FormGroup>
                                <input
                                  key={this.state.fileInputKey}
                                  type="file"
                                  id="file"
                                  onChange={this.onChangeHandler}
                                  name="file"
                                  multiple
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    backgroundColor: "#f9f9f9",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    paddingTop: 15,
                                    paddingBottom: 15,
                                    paddingLeft: 15,
                                    borderRadius: 5
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {["text", "imagetext"].indexOf(db.systemType) !==
                            -1 && (
                            <Col md={12}>
                              <FormGroup>
                                <Label>
                                  <AppLanguage id="upload_placeholder" />
                                </Label>
                                <Input
                                  type="text"
                                  value={this.state.text || ""}
                                  onChange={this.onChangeHandler}
                                  name="text"
                                  id="text"
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className="modal-footer clearfix">
                        <Container>
                          {this.state.uploading && (
                            <Row>
                              <Col md={12}>
                                <Progress
                                  value={
                                    this.state.progress /
                                    this.state.files.length
                                  }
                                  color="success"
                                >
                                  {Math.round(
                                    this.state.progress /
                                      this.state.files.length
                                  )}
                                  %
                                </Progress>
                              </Col>
                            </Row>
                          )}
                          {this.state.errorUploading && (
                            <Row>
                              <Col md={12}>
                                <div className="text-danger">
                                  <AppLanguage id="upload_problems" />
                                </div>
                              </Col>
                            </Row>
                          )}
                          {this.state.successfullUpload && (
                            <Row>
                              <Col md={12}>
                                <div className="text-success">
                                  <AppLanguage id="upload_complete" />
                                </div>
                              </Col>
                            </Row>
                          )}
                          {(this.state.uploading ||
                            this.state.errorUploading ||
                            this.state.successfullUpload) && (
                            <Row>
                              <Col md={12}>
                                <br />
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col md={12}>
                              <Button
                                color="success"
                                size="lg"
                                block
                                type="submit"
                                style={{ fontSize: "1.3em" }}
                              >
                                <AppLanguage id="upload_button" />
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                  <div className="text-center text-white opacity-8 mt-3">
                    <AppLanguage id="upload_company" />
                  </div>
                </fieldset>
              </Form>
            </Col>
          </div>
        </div>
      </Fragment>
    );
  }
}
