import React, {Fragment} from 'react';

import {
		UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover,
		Nav, NavLink, Col, Row, NavItem, UncontrolledButtonDropdown, Button
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';

import bg2 from '../../../assets/utils/images/dropdown-header/abstract2.jpg';
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';

import {
		faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class MegaMenu extends React.Component {
		constructor(props) {
				super(props);

				this.toggle = this.toggle.bind(this);
				this.state = {
						dropdownOpen: false,
						popoverOpen: false,
				};
		}

		toggle() {
				this.setState({
						dropdownOpen: !this.state.dropdownOpen,
						popoverOpen: !this.state.popoverOpen

				});
		}

		state = {};

		render() {
				return (
						<Fragment>
								<Nav className="header-megamenu">
										<UncontrolledDropdown nav inNavbar>
												<DropdownToggle nav>
														<i className="nav-link-icon pe-7s-settings"> </i>
														Melodi Grand Prix 2019
														<FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown}/>
												</DropdownToggle>
												<DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg rm-pointers">
														<div className="dropdown-menu-header">
																<div className="dropdown-menu-header-inner bg-primary">
																		<div className="menu-header-image opacity-1"
																				 style={{
																						 backgroundImage: 'url(' + bg3 + ')'
																				 }}
																		></div>
																		<div className="menu-header-content text-left">
																				<h5 className="menu-header-title">Projects</h5>
																				{/*<h6 className="menu-header-subtitle"></h6>*/}
																				<div className="menu-header-btn-pane">
																						<Button size="sm" color="success" className="mr-2 text-white">
																								<i className="pe-7s-plus btn-icon-wrapper"></i>&nbsp;
																								New project
																						</Button>
																				</div>
																		</div>
																</div>
														</div>
														<DropdownItem>
																<i className="dropdown-icon pe-7s-settings"> </i>
																Graphic Design
														</DropdownItem>
														<DropdownItem>
																<i className="dropdown-icon lnr-file-empty"> </i>
																App Development
														</DropdownItem>
														<DropdownItem>
																<i className="dropdown-icon lnr-file-empty"> </i>
																Icon Design
														</DropdownItem>
														{/*<DropdownItem divider/>*/}
														<DropdownItem>
																<i className="dropdown-icon lnr-file-empty"> </i>
																Miscellaneous
														</DropdownItem>
														<DropdownItem>
																<i className="dropdown-icon lnr-file-empty"> </i>
																Frontend Dev
														</DropdownItem>
												</DropdownMenu>
										</UncontrolledDropdown>
								</Nav>
						</Fragment>
				)
		}
}

export default MegaMenu;
