import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import './assets/base.scss';
import 'react-input-range/lib/css/index.css';
import Main from './Pages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { BackendContextProvider } from './utils/backendStoreSocketClient';

const store = configureStore();
const rootElement = document.getElementById('root');


const renderApp = Component => {
	ReactDOM.render(
		<Provider store={store}>
			<BackendContextProvider>
				<Router>
					<Component />
				</Router>
			</BackendContextProvider>
		</Provider>,
		rootElement
	);
};

renderApp(Main);

if (module.hot) {
	module.hot.accept('./Pages/Main', () => {
		const NextApp = require('./Pages/Main').default;
		renderApp(NextApp);
	});
}
serviceWorker.unregister();
