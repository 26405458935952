/* eslint-disable no-plusplus */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
const uuidv4 = require('uuid/v4');

function makeUUID() {
  return uuidv4();
}

export {
  makeUUID
};
