import React from "react";
import style from "./Effect/style.module.css";
import BgImage from "./Effect/bg.jpg";

class BackgroundImage extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "0 0 url(" + (this.props.url || BgImage) + ") no-repeat",
          backgroundSize: "cover",
          ...style.box
        }}
      />
    );
  }
}

export default BackgroundImage;
