import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { backendContext } from 'utils/backendStoreSocketClient'

export default class BitfocusWaitForBackend extends React.Component {

  static contextType = backendContext

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {

    const backend = this.context || {}

    return (
      <BlockUi tag="div" blocking={!backend.socket.connected}>
        {this.props.children}
      </BlockUi>
    );
  }
}