import React, { Fragment } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import ReverseRain from "./Effect/ReverseRain";
import SingleSwoop from "./Effect/SingleSwoop";
import Sociawall from "./Effect/Sociawall.tsx";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { backendContext } from "utils/backendStoreSocketClient";
import BackgroundImage from "./BackgroundImage";
import { useContext, useState, useEffect } from "react";
import { useImageCache } from "./useImageCache.tsx";
export const Application = () => {
  // context
  const context = useContext(backendContext);

  // state
  const [db, setDb] = useState(null);
  const [uncachedImages, setUncachedImages] = useState([]);
  const [screenEffect, setScreenEffect] = useState("ReverseRain");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [colors, setColors] = useState([]);
  const [textcolor, setTextcolor] = useState("#ffffff");

  // effects
  useEffect(() => {
    const backend = context || {};
    const backendState = backend.state || {};
    const newDb = backendState.db;
    console.log("newDb", newDb);

    if (!newDb) return;
    setDb(newDb);

    console.log("videre");

    const images =
      newDb.imageset !== undefined
        ? newDb.imageset.filter(obj => obj.approved === true)
        : [];

    let colors = (newDb.colors || [])
      .filter(color => color.type === "cardbg")
      .map(color => color.value);

    let textcolor = (newDb.colors || []).find(
      color => color.type === "cardtext"
    );
    textcolor = textcolor ? textcolor.value : "#ffffff";

    const backgroundUrl = newDb.backgroundUrl;
    const screenEffect = newDb.screenEffect;

    setUncachedImages(images);
    setColors(colors);
    setTextcolor(textcolor);
    setBackgroundUrl(backgroundUrl);
    setScreenEffect(screenEffect);
  }, [context]);

  const images = useImageCache(uncachedImages);

  return (
    <Fragment>
      {db && (
        <>
          <link
            rel="stylesheet"
            type="text/css"
            href={
              "https://fonts.googleapis.com/css?family=" +
              (db && db.font ? db.font : "Arial")
            }
          />
          {images.length > 0 && screenEffect === "ReverseRain" && (
            <ReverseRain
              imageset={images}
              backgroundUrl={backgroundUrl}
              pps={db.pps || 50}
              between={db.between || 1000}
              imagesize={db.imagesize || 500}
              fontsize={db.fontsize || 100}
              font={db.font || "Arial"}
              colors={colors}
              textcolor={textcolor}
            />
          )}
          {images.length > 0 && screenEffect === "Sociawall" && (
            <Sociawall
              imageset={images}
              imagesize={db.imagesize || 200}
              between={db.between || 1000}
              font={db.font || "Arial"}
              colors={colors}
              textcolor={textcolor}
              fontsize={db.fontsize || 100}
              enableText={db.systemType !== "image"}
              backgroundUrl={backgroundUrl}
            />
          )}
          {images.length > 0 && screenEffect === "SingleSwoop" && (
            <SingleSwoop
              imageset={images}
              backgroundUrl={backgroundUrl}
              between={db.between || 1000}
              imagesize={db.imagesize || 500}
              fontsize={db.fontsize || 100}
              font={db.font || "Arial"}
              colors={colors}
              textcolor={textcolor}
            />
          )}
          {images.length < 1 && <BackgroundImage url={backgroundUrl} />}
        </>
      )}
    </Fragment>
  );
};

export default Application;
