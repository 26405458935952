export const getMainNav = (state) => ([
	{
		icon: 'pe-7s-home',
		label: 'Moderering',
		to: '/#/app',
	},
	{
		icon: 'pe-7s-tools',
		label: 'Innstillinger',
		to: '/#/app/config',
	},
	...(state.instagramModule ? [
		{
			icon: 'pe-7s-camera',
			label: 'Instagram',
			to: '/#/app/instagram',
		}
	] : []),
]);
